"use client";

import useSWR, { mutate } from "swr";

import { Cart } from "@/types/index";
import { getPricingHeaders } from "@/lib/utils/index";
import { fetchWrapper, swrFetcher } from "./fetch-client";

const useCart = () => {
	let cartId = typeof window !== "undefined" ? window.localStorage.getItem("cart_id") : null;
	const config = getPricingHeaders();
	const ckId =
		typeof window !== "undefined" ? window.localStorage.getItem("ck_subscriber_id") : null;

	const createCart = async () => {
		const { data: cart } = await fetchWrapper<{ data: Cart }>("/v1/cart", {
			method: "POST",
		});

		window.localStorage.setItem("cart_id", cart?.id);
		cartId = cart?.id;
		mutate(`/v1/cart`);
	};

	const getCart = () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useSWR<Cart>(`/v1/cart`, async (url) : Promise<Cart> => {
			const id : string = window.localStorage.getItem("cart_id");
			return id ? (<any> (await fetchWrapper(`${url}/${id}`, {
				headers: config.headers,
			}))).data : Promise.resolve();
		}, {revalidateOnFocus: false});
	}

	const addToCart = async (cartId: string, planId: string) => {
		const res = await fetchWrapper(`/v1/cart/${cartId}`, {
			method: "PUT",
			body: JSON.stringify({
				plan_id: planId,
				ck_id: ckId,
			}),
		});
		mutate(`/v1/cart`);
		return res;
	};

	const destroyCart = () => {
		mutate(`/v1/cart/`, null);
		cartId = null;
		localStorage.removeItem("cart_id");
	};

	return { cartId, createCart, addToCart, destroyCart, getCart };
};

export default useCart;
