"use client";

import { useEffect, useState } from "react";

import useCart from "@/lib/hooks/use-cart";

const Cart = () => {
	const { cartId, createCart } = useCart();
	const [pageReady, setReady] = useState<boolean>(false);

	useEffect(() => {
		if (pageReady) {
			createCart();
		}
	}, [pageReady, createCart]);

	// due
	useEffect(() => {
		if (cartId === "undefined" || !cartId) {
			setReady(true);
		}
	}, [cartId, createCart]);

	return null;
};

export default Cart;
